import React from "react";
import { useSelector } from "react-redux";
import HeaderAfterLogin from "../containers/HeaderAfterLogin";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import BeforeLoginFooter from "../containers/BeforeLoginFooter";

const CommonRoutes = ({ children, disableHeader, disableFooter }) => {
  const { isAuth } = useSelector((state) => state.auth);

  if (isAuth) {
    return (
      <>
        {disableHeader ? null : <HeaderAfterLogin />}
        {children}
        {disableFooter ? null : <Footer />}
      </>
    );
  } else {
    return (
      <>
        {disableHeader ? null : <Header />}
        {children}
        {disableFooter ? null : <BeforeLoginFooter />}
      </>
    );
  }
};
export default CommonRoutes;
