import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  logoutUser,
  setPassword,
  updateProfile,
} from "../../redux/auth/actions";
import { Toaster } from "../../utils/toaster";
import { APP_CONST, UPDATE_PASSWORD_FLAG_KEY } from "../../config/const";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

const UpdatePasswordModal = ({ show, setShow }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [passwordVisibilty, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  // Validate password fields
  const validateData = (data) => {
    const dataToValidate = data ? data : formData;
    const { confirmPassword, newPassword, fullName } = dataToValidate;

    let errorObj = {};
    if (!Boolean(fullName)) {
      errorObj.fullName = "Full name is required";
    }
    if (!Boolean(confirmPassword)) {
      errorObj.confirmPassword = "Confirm password is required";
    }
    if (!Boolean(newPassword)) {
      errorObj.newPassword = "Password is required";
    } else if (confirmPassword !== newPassword) {
      errorObj.confirmPassword = "Confirm password and password must match";
    }
    setErrors(errorObj);
    return errorObj;
  };

  // Handle change
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const data = { ...formData, [name]: value };
    validateData(data);
    setFormData(data);
  };

  // Handle profile update
  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    const vaildationErrors = validateData(formData);
    if (Object.values(vaildationErrors).length === 0) {
      setIsLoading(true);
      const profilePromise = await updateProfile({
        fullName: formData?.fullName,
      });
      const passwordPromise = await setPassword({
        newPassword: formData?.newPassword,
      });
      setIsLoading(false);
      if (profilePromise && passwordPromise) {
        dispatch(logoutUser());
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Profile updated");
      }
      handleClose();
    }
  };

  const handleClose = () => {
    localStorage.removeItem(UPDATE_PASSWORD_FLAG_KEY);
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        className="changePasswordModal UpdateProfileModal"
        // onHide={}
      >
        <Modal.Header>
          <div className="d-flex flex-column">
            <Modal.Title>Update Your Account Details</Modal.Title>
            <p className="accountUsername">
              <b>Your Username : </b>
              {user?.userName}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleProfileUpdate}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter full name"
                name="fullName"
                onChange={handleChange}
                isInvalid={!!errors.fullName}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.fullName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicEmail"
            >
              <Form.Control
                type={passwordVisibilty.password === true ? "text" : "password"}
                placeholder="Enter new Password"
                name="newPassword"
                onChange={handleChange}
                isInvalid={!!errors.newPassword}
              />
              <span
                className="icon"
                onClick={() =>
                  setPasswordVisibility((p) => ({
                    ...p,
                    password: !p.password,
                  }))
                }
              >
                {passwordVisibilty.password ? (
                  <IoEyeOffOutline />
                ) : (
                  <IoEyeOutline />
                )}
              </span>
              <Form.Control.Feedback type="invalid">
                {errors?.newPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicEmail"
            >
              <Form.Control
                type={
                  passwordVisibilty.confirmPassword === true
                    ? "text"
                    : "password"
                }
                placeholder="Enter confirm password"
                name="confirmPassword"
                onChange={handleChange}
                isInvalid={!!errors.confirmPassword}
              />
              <span
                className="icon"
                onClick={() =>
                  setPasswordVisibility((p) => ({
                    ...p,
                    confirmPassword: !p.confirmPassword,
                  }))
                }
              >
                {passwordVisibilty.confirmPassword ? (
                  <IoEyeOffOutline />
                ) : (
                  <IoEyeOutline />
                )}
              </span>
              <Form.Control.Feedback type="invalid">
                {errors?.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <p>
              <b>Note:</b> Save above username & set password for future use to
              login to your account.
              <p>
                "The Password reset is valid for only 10 minutes. please ensure
                you complete the process within this time to successfully reset
                your password."
              </p>
            </p>

            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? "Loading..." : "Submit Details"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UpdatePasswordModal;
