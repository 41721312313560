export const APP_CONST = Object.freeze({
  AUTH_ACTIONS: {
    LOGIN: "login",
    REGISTER: "register",
  },
  TOAST_TYPE: {
    ERROR: "error",
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
  },
  SPORTS_ID: {
    CIRCKET: "4",
    SOCCER: "1",
    TENNIS: "2",
  },
  ALL_MARKET_IDS: {
    CRICKET: "CRICKET_IDS",
    SOCCER: "SOCCER_IDS",
    TENNIS: "TENNIS_IDS",
  },
  ODDS_TYPE: {
    BACK: "back",
    LAY: "lay",
  },
  PLAYER_BANK_DETAILS_TYPE: {
    Bank: "bank",
    Card: "card",
  },
  TEST: {},
  AUTH_MODAL: {
    LOGIN: "login",
    REGISTER: "register",
    MOBILE_NUMBER: "mobileNumber",
    VERIFY_NUMBER: "verifyNumber",
    FORGOTO_PASSWORD: "forgotpassword",
    RESET_PASSWORD: "resetpassword",
  },
  AUTH_MODAL_ACTION_TYPE: {
    MANUAL: "manual",
    AUTO: "auto",
  },
  ORGANISATION_STATUS: {
    Maintenance: "maintenance",
    Live: "live",
    Suspended: "suspended",
  },
  ENVIRONMENTS: {
    PRODUCTION: "production",
    DEVELOPMENT: "development",
  },
});

export const WITHDRAW_MODES = Object.freeze({
  Bank: "bank",
  Upi: "upi",
  M_Crypto: "m_crypto",
  M_Bkash: "m_bkash",
  M_Nagad: "m_nagad",
  M_Binance: "m_binance",
  M_BinanceTron: "m_binance_tron",
  M_BinanceBSC: "m_binance_bsc",
  M_Upay: "m_upay",
  M_Rocket: "m_rocket",
  Crypto: "crypto",
  M_Paytm: "m_paytm",
  M_Bitcoin: "m_bitcoin_btc",
  M_Ethereum: "m_ethereum_erc20",
  M_Litecoin: "m_litecoin_ltc",
  M_Usdt: "m_usdt_erc20",
  Nagad: "nagad",
  Bkash: "bkash",
  Upay: "upay",
  Rocket: "rocket",
  SureCash: "surecash",
  Okwallet: "okwallet",
  Mcash: "mcash",
});

export const DEFAULT_APP_ROUTES = Object.freeze({
  PUBLIC: "/sign-in",
  PROTECTED: "/home",
});

export const UPDATE_PASSWORD_FLAG_KEY = "psdupdt";

export const gameTypes = Object.freeze({
  new: "new",
  popular: "popular",
  lastPlayed: "lastPlayed",
  favourites: "favourites",
});
