import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { otpLessLogin } from "../../redux/auth/actions";
import WaitingLoader from "../../assets/images/waiting-loader.gif";

const OtpLessUi = ({
  agentCode = "",
  showOtpLessModal,
  setShowOtpLesModal,
}) => {
  const dispatch = useDispatch();
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    window.otpless = (otplessUser) => {
      let payload = {
        token: otplessUser?.token,
      };
      if (agentCode && agentCode !== "") {
        payload["agent_code"] = agentCode;
      }
      dispatch(otpLessLogin(payload));
    };
  }, []);

  useLayoutEffect(() => {
    const script = document.createElement("script");
    script.id = "otpless-sdk";
    script.type = "text/javascript";
    script.src = "https://otpless.com/v3/auth.js";
    script.setAttribute("data-appid", "GQMOM1O8REG3ZF8LQ8JA");
    document.body.appendChild(script);
    const intervalId = setInterval(() => {
      const iframe = document.querySelector("#otpless-login-page iframe");
      if (iframe && iframe.contentWindow) {
        console.log("Iframe loaded!");
        setIframeLoaded(true);
        clearInterval(intervalId);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={showOtpLessModal ? "SignRegister" : "SigninModal"}>
      <button
        className="CloseBtn"
        onClick={() => setShowOtpLesModal(false)}
      ></button>
      {!iframeLoaded ? (
        <div className="LoaderPleasewait">
          <img src={WaitingLoader} alt="WaitingLoader" />
          <p>Please Wait....</p>
        </div>
      ) : null}
      <div id="otpless-login-page"></div>
    </div>
  );
};
export default OtpLessUi;
