import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { FaSpinner } from "react-icons/fa";
import Close from "./../../assets/images/icons/left-menu/close.svg";
import Exchange from "./../../assets/images/icons/left-menu/exchange.svg";
import Sportsbook from "./../../assets/images/icons/left-menu/sportsbook.svg";
import Casino from "./../../assets/images/icons/left-menu/casino.svg";
import LiveCasino from "./../../assets/images/icons/left-menu/live-casino.svg";
import ArcadeGames from "./../../assets/images/icons/left-menu/arcade-games.svg";
import Aviator from "./../../assets/images/icons/left-menu/aviator.svg";
import Esports from "./../../assets/images/icons/left-menu/e-sports.png";
import Offers from "./../../assets/images/icons/left-menu/offers.svg";
import LiveSupport from "./../../assets/images/icons/left-menu/live-support.svg";
import VIPClub from "./../../assets/images/icons/left-menu/vip-club.svg";
import Blogs from "./../../assets/images/icons/left-menu/blogs.svg";
import BecomeAnAffiliate from "./../../assets/images/icons/left-menu/become-an-affiliate.svg";
import ArrowRight from "./../../assets/images/icons/left-menu/arrow-right.svg";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const htmlElement = document.documentElement;
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const handleLeftBarClick = () => {
    // htmlElement.style.overflow = "auto";
    document.body.classList.remove("leftbar-opened");
  };

  return (
    <>
      <div className="leftbarSec">
        <div className="top-head d-md-none">
          <a href="/home">
            {appDetails?.LOGO_URL ? (
              <img src={appDetails?.LOGO_URL} alt="Logo" />
            ) : (
              <FaSpinner />
            )}
          </a>

          <button
            type="button"
            className="close-btn"
            onClick={handleLeftBarClick}
          >
            <img src={Close} alt="close" />
          </button>
        </div>

        <div className="links games_link">
          <ul>
            <li>
              <a href="/sports/Inplay">
                <img src={Exchange} alt="exchange" />
                <span>Exchange</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="/atlas-lobby">
                <img src={Sportsbook} alt="sportsbook" />
                <span>Sportsbook</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="/casino/ezugi">
                <img src={Casino} alt="casino" />
                <span>Casino</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="/casino/evolution">
                <img src={LiveCasino} alt="live casino" />
                <span>Live Casino</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="/casino/spribe/aviator">
                <img src={Aviator} alt="aviator" />
                <span>Aviator</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src={ArcadeGames} alt="arcade games" />
                <span>Arcade Games</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="/sports/Inplay">
                <img src={Esports} alt="esports" />
                <span>Esports</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
          </ul>
        </div>

        <div className="links others_link">
          <ul>
            <li>
              <a href="javascript:void(0)">
                <img src={Offers} alt="offers" />
                <span>Offers</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src={LiveSupport} alt="live support" />
                <span>Live Support</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src={VIPClub} alt="vip club" />
                <span>VIP Club</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src={Blogs} alt="blogs" />
                <span>Blogs</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src={BecomeAnAffiliate} alt="become an affiliate" />
                <span>Become an Affiliate</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default LeftBarSports;
